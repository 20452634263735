import React from 'react';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import AIContentList from '../components/AIContentList';
import HomeLogos from '../components/HomeLogos';
import MaBanner from '../images/ai-banner-image.png';

export default () => (
  <Layout current="products" subCurrent="aihub">
    <ProductsBanner
      title="AI Hub数据智能引擎"
      description="在存量经营的时代，我们更迫切的需要做精准营销。AI Hub能够提供一整套针对营销业务建模和分析的解决方案，每个企业都可以结合自身的营销目的，组合特征数据，产出更能增加效益的专属模型。"
      img={MaBanner}
      imgWidth={445}
    />
    <AIContentList />
    <HomeLogos />
  </Layout>
);
