import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import AI1 from '../images/ai-1.png';
import AI2 from '../images/ai-2.png';
import AI3 from '../images/ai-3.png';
import AI4 from '../images/ai-4.png';
import AI5 from '../images/ai-5.png';

export default () => {
  return (
    <div className="content-list-wrapper">
      <div>
        <div />
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={AI1}
              imgWidth={417}
              title="洞察分析AI："
              title2="在千万种组合中快速获得洞察让决策更科学"
              position="right"
            >
              <ImageTextDescription label="自动对数据进行相关性计算以及探索性分析" />
              <ImageTextDescription label="通过特征自动建议和模型聚类，对人群、产品或其他业务目的进行洞察分析，找出重要特征" />
              <ImageTextDescription label="自动形成可视化洞察图表" />
              <ImageTextDescription
                label="部分模型用例"
                content="Persona分析，品类偏好分析，RFM分析，市场调研分析，活动日志分析等"
              />
              <ImageTextDescription
                label="应用场景"
                content="结合MA精准营销，结合CDP挖掘分析数据，结合数据分析咨询服务"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={AI2}
              imgWidth={447}
              title="预测类AI："
              title2="训练自我业务检验、预测的模型，让策略更精准"
              position="left"
            >
              <ImageTextDescription label="通过历史数据，学习针对目标参数，支持专家经验输入" />
              <ImageTextDescription label="无代码一键训练，自动样本均衡，模型调参数" />
              <ImageTextDescription label="预测结果解释强，业务人员看的懂" />
              <ImageTextDescription label="发布的服务可转换为智能标签，与业务系统集成" />
              <ImageTextDescription
                label="模型用例"
                content="购买概率预测，折扣偏好预测，货找人，线索打分，流失评级等"
              />
              <ImageTextDescription label="应用场景" content="结合MA、结合企业的智能运营系统" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={AI3}
              imgWidth={435}
              title="千人千面AI："
              title2="从海量稀疏的行为数据中，统一提取人-人、或人-物、或物-物之间关系的数学表达，应用个性化展示"
              position="right"
            >
              <ImageTextDescription label="嵌入模型主要服务于个性化推荐" />
              <ImageTextDescription label="模型可以自动迭代，不断学习新的行为数据" />
              <ImageTextDescription label="支持实时调用，响应速度快" />
              <ImageTextDescription label="模型用例" content="物物推荐，人人推荐，人物推荐" />
              <ImageTextDescription label="应用场景" content="APP/小程序/web自建站的个性化推荐" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={AI4}
              imgWidth={396}
              title="销售分析AI："
              title2="对人流、场域、产品综合建模，让经营更省成本，提升利润率"
              position="left"
            >
              <ImageTextDescription label="哪个营销渠道（线上广告、微信推送、门店海报等）对销售额影响最大" />
              <ImageTextDescription label="哪种类型的促销事件对销售的影响比较大" />
              <ImageTextDescription label="对细分客户应该采取哪种最佳的行动，以获得最大的收益" />
              <ImageTextDescription label="减少决策错误，提高投资回报率" />
              <ImageTextDescription
                label="模型用例"
                content="渠道销量预测、渠道流量预测、促销事件分析、供应商评分"
              />
              <ImageTextDescription
                label="应用场景"
                content="线上线下渠道销售额，活动智能运营，商品定价策略等"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText
              image={AI5}
              imgWidth={415}
              title="可集成、可持续学习的轻量级AI引擎"
              position="right"
            >
              <ImageTextDescription label="管理所有对业务有用的特征数据，提供线上和线下的特征数据服务" />
              <ImageTextDescription label="无代码，一键训练业务模型" />
              <ImageTextDescription label="可私有部署，集成CDP、MA" />
              <ImageTextDescription label="支持隐私计算，获取更多第三方金融属性、消费数据等标签" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
